import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import useRouter from "common/hooks/use-router";
import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import { User } from "@sentry/react";
import { NotificationCampaign } from "types/NotificationCampaign";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import ParkingSelect from "components/select/Parking/ParkingSelect";

function NotificationCampaignForm({ onSubmit }: any) {
  const { t } = useTranslation();
  const { navigate } = useRouter();

  const initialValues: User = useMemo(() => {
    return {
      path: "/",
      filters: {
        parking: null,
      },
    };
  }, []);

  const onSubmitWrapper = async (values: NotificationCampaign) => {
    const res = await onSubmit(values);

    return res;
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
          {/* Header */}
          <EditHeader
            title={t("newItem.notificationCampaign")}
            subTitle={values.title}
            icon="BellAlertIcon"
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={() => {
                  navigate("/notification-campaigns");
                }}
              />
            }
          />
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FormScrollError />

            <div className="responsive-flex gap-2 mt-2">
              <div className="white-box flex-1">
                <div className="flex flex-col gap-2">
                  <SectionTitle
                    icon="BellAlertIcon"
                    title={t("forms.notification")}
                  />
                  <FormControl
                    name="title"
                    titleKey={t("forms.notificationTitle")}
                    required
                  />
                  <FormControl
                    name="description"
                    titleKey={t("forms.notificationDescription")}
                    required
                  />
                </div>
              </div>
              <div className="white-box flex-1">
                <div className="flex flex-col gap-2">
                  <SectionTitle
                    icon="BuildingOfficeIcon"
                    title={t("forms.parking")}
                  />
                  <FormControl
                    name="filters.parking"
                    titleKey={t("forms.parkingFollowed")}
                    type="custom"
                  >
                    <ParkingSelect onlyId />
                  </FormControl>
                </div>
              </div>
            </div>
            <HeaderButtonsEdit
              onSubmit={handleSubmit}
              onCancel={() => navigate("/notification-campaigns")}
            />
          </form>
        </div>
      )}
    />
  );
}
export default NotificationCampaignForm;
