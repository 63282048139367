import Login from "pages/Auth/Login";
import Page404 from "pages/Page404";
import Home from "pages/Home/Home";
import Users from "pages/Users/Users";
import User from "pages/Users/User";
import MyAccount from "pages/Users/MyAccount";
import Support from "pages/Support/Support";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import Parkings from "pages/Parkings/Parkings";
import Parking from "pages/Parkings/Parking";
import ParkingTypes from "pages/ParkingTypes/ParkingTypes";
import ParkingType from "pages/ParkingTypes/ParkingType";
import Services from "pages/Services/Services";
import Service from "pages/Services/Service";
import Notifications from "pages/Notifications/Notifications";
import Notification from "pages/Notifications/Notification";
import Stats from "pages/Stats/Stats";
import NotificationCampaign from "pages/NotificationCampaigns/NotificationCampaign";
import NotificationCampaigns from "pages/NotificationCampaigns/NotificationCampaigns";

const managers = ["Admin"];
const pros = ["Admin", "Authenticated"];

const routes = [
  { path: "/parkings", roles: pros, Component: Parkings },
  { path: "/parkings/add", roles: pros, Component: Parking },
  { path: "/parkings/edit/:id", roles: pros, Component: Parking },
  { path: "/parkings/duplicate/:id", roles: pros, Component: Parking },
  { path: "/parking-types", roles: pros, Component: ParkingTypes },
  { path: "/parking-types/add", roles: pros, Component: ParkingType },
  { path: "/parking-types/edit/:id", roles: pros, Component: ParkingType },
  { path: "/", roles: pros, Component: Stats },
  { path: "/services", roles: pros, Component: Services },
  { path: "/services/add", roles: pros, Component: Service },
  { path: "/services/edit/:id", roles: pros, Component: Service },
  { path: "/notifications", roles: pros, Component: Notifications },
  { path: "/notifications/add", roles: pros, Component: Notification },
  { path: "/notifications/edit/:id", roles: pros, Component: Notification },
  {
    path: "/notification-campaigns",
    roles: pros,
    Component: NotificationCampaigns,
  },
  {
    path: "/notification-campaigns/add",
    roles: pros,
    Component: NotificationCampaign,
  },
  { path: "/users", roles: managers, Component: Users },
  { path: "/users/add", roles: managers, Component: User },
  { path: "/users/edit/:id", roles: managers, Component: User },
  { path: "/my-account", roles: pros, Component: MyAccount },
  { path: "/support", roles: "all", Component: Support },
  { path: "/dashboard", roles: "all", Component: Home },
  { path: "*", roles: "all", exact: false, Component: Page404 },
];

export const loginRoutes = [
  { path: "/login", roles: "all", Component: Login },
  { path: "/forgot-password", roles: "all", Component: ForgotPassword },
  { path: "/reset-password", roles: "all", Component: ResetPassword },
  { path: "*", roles: "all", Component: Login },
];

export default routes;
