import { useCallback, useEffect, useState } from "react";
import CancellablePromise from "common/utils/CancellablePromise";
import qs from "qs";
import { parseStrapiFormat } from "common/utils/strapi";

import { endOfDay, startOfDay } from "date-fns";
import {
  addEventListener,
  Listener,
  removeEventListener,
} from "../providers/SocketProvider";
import useFetch, { UseFetchOptions } from "./useFetch";
import useMemoryState from "./useMemoryState";

interface Map {
  [key: string]: string | number | boolean | object;
}

export type UseListOptions = {
  defaultFilters?: any;
  defaultSort?: string;
  defaultSorts?: string[];
  defaultGroupBy?: string;
  populate?: string | object;
  fields?: string | object;
  listenToEvents?: string[];
  overrideItemsPerPage?: number;
} & UseFetchOptions;

export type UseListType<T> = {
  items: T[] | null;
  allItemsFetched: boolean;
  isPreviousItems: boolean;
  isItemsValid: boolean;
  error: string | null;
  isFetching: boolean;
  fetchItems: (...args: any[]) => CancellablePromise<unknown>;

  pageCount: number;
  pageIndex: number;
  itemsCount: number;
  setPageIndex: (value: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;

  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  setSorts: React.Dispatch<React.SetStateAction<string[]>>;

  groupBy: string;
  setGroupBy: React.Dispatch<React.SetStateAction<string>>;

  filters: Map;
  setFilter: (key: string, value: number | boolean | string) => void;
  setFilters: (value: Map) => void;

  removeListItem: (id: number | string) => void;
  addListItem: (item: T) => void;

  fetchNextItems: () => void;
};

const useList = <T,>(
  url: string,
  options: UseListOptions = {}
): UseListType<T> => {
  const {
    defaultFilters = {},
    defaultSort,
    defaultSorts,
    defaultGroupBy,
    populate = null,
    fields = null,
    overrideItemsPerPage = null,
    listenToEvents = [],
    ...opts
  } = options;

  opts.sharePromise = false;

  opts.cachePrefix = opts.cachePrefix ?? `list_${url.replace(/[/?&]/g, "_")}`;

  const [itemsCount, setItemsCount] = useMemoryState<number>(
    0,
    `${opts.cachePrefix}itemsCount`
  );
  const [pageIndex, setPageIndex] = useMemoryState<number>(
    0,
    `${opts.cachePrefix}pageIndex`
  );
  const [itemsPerPage, setItemsPerPage] = useMemoryState<number>(
    overrideItemsPerPage || 50,
    `${opts.cachePrefix}itemsPerPage`
  );
  const [filters, setFilters] = useMemoryState<any>(
    defaultFilters,
    `${opts.cachePrefix}filters`
  );
  const [sort, setSort] = useMemoryState<string>(
    defaultSort,
    `${opts.cachePrefix}sort`
  );
  const [sorts, setSorts] = useMemoryState<any>(
    defaultSorts,
    `${opts.cachePrefix}sorts`
  );
  const [groupBy, setGroupBy] = useMemoryState<string>(
    defaultGroupBy,
    `${opts.cachePrefix}groupBy`
  );
  const [items, setItems] = useState<T[]>([]);

  opts.postExecute = useCallback(
    (data: any) => {
      let newItems: any = null;
      if (Array.isArray(data)) {
        setItemsCount(data.length);
        newItems = data;
      } else {
        setItemsCount(data.meta?.pagination?.total ?? "1");
        newItems = parseStrapiFormat(data);
      }
      setItems(() => {
        /* if (newItems) {
          if (pageIndex === 1) {
            return newItems;
          }
          const mergedItems = [...oldItems, ...newItems];
          return mergedItems;
        }
        return oldItems; */
        return newItems;
      });

      return newItems;
    },
    [setItemsCount]
  );

  const getUrl = () => {
    const query = {
      sort: [],
      filters: {},
      pagination:
        overrideItemsPerPage === -1
          ? null
          : {
              page: pageIndex + 1,
              pageSize: itemsPerPage as number,
            },
    } as {
      sort: string[];
      filters: {
        [key: string]:
          | {
              [key: string]: string | number | boolean;
            }
          | object;
      };
      pagination?: {
        page: number;
        pageSize: number;
      };
      populate?: string | object;
      fields?: string | object;
    };

    if (populate) {
      query.populate = populate;
    }
    if (fields) {
      query.fields = fields;
    }

    if (groupBy) {
      query.sort.push(groupBy);
    }
    if (sort) {
      query.sort.push(sort);
    }
    if (sorts) {
      query.sort.push(...sorts);
    }

    Object.keys(filters).forEach((filter) => {
      if (
        typeof filters[filter] === "string" ||
        filters[filter] instanceof Date
      ) {
        const [fieldName, operator = "eq"] = filter.split("$");
        if (operator === "date") {
          query.filters[fieldName] = {
            [`$gte`]: startOfDay(filters[filter] as Date),
            [`$lte`]: endOfDay(filters[filter] as Date),
          };
        } else {
          query.filters[fieldName] = { [`$${operator}`]: filters[filter] };
        }
      } else {
        query.filters[filter] = filters[filter] as object;
      }
    });

    const queryParams = qs.stringify(query, {
      encodeValuesOnly: true,
    });

    return `${url}${queryParams ? "?" : ""}${queryParams}`;
  };

  const { isDataValid, error, setData, isFetching, isPreviousData, fetchData } =
    useFetch<T[]>(getUrl(), opts);

  const setFilter = useCallback(
    (key: any, value: any) => {
      setFilters((prevFilters: Map) => {
        if (value) {
          return { ...prevFilters, [key]: value };
        }
        const newFilters = { ...prevFilters };

        delete newFilters[key];
        return newFilters;
      });
      setPageIndex(0);
    },
    [setFilters, setPageIndex]
  );

  const removeListItem = useCallback(
    (id: any) => {
      setData((oldData: T[] | null) =>
        oldData
          ? oldData.filter((d) => (d as T & { id: string | number }).id !== id)
          : null
      );
    },
    [setData]
  );
  const addListItem = useCallback(
    (item: T) => {
      setData((oldData: T[] | null) => (oldData ?? []).concat(item));
    },
    [setData]
  );

  const allItemsFetched = itemsCount - pageIndex * itemsPerPage <= 0;

  const fetchNextItems = useCallback(() => {
    setPageIndex((prev) => {
      return prev + 1;
    });
  }, [setPageIndex]);

  useEffect(() => {
    if (listenToEvents && listenToEvents.length > 0) {
      const listener: Listener = {
        events: listenToEvents,
        handleEvent: () => {
          // if pageIndex already = 1, fetchData will not be trigger, so trig it manually
          if (pageIndex !== 1) {
            setPageIndex(1);
          } else {
            fetchData();
          }
        },
      };
      addEventListener(listener);

      return () => {
        removeEventListener(listener);
      };
    }
    return () => {};
  }, [listenToEvents, fetchData, pageIndex, setPageIndex]);

  return {
    items,
    isItemsValid: isDataValid,
    isPreviousItems: isPreviousData,
    error,
    isFetching,
    fetchItems: fetchData,

    pageCount: Math.ceil(itemsCount / itemsPerPage),
    pageIndex,
    setPageIndex,
    itemsPerPage,
    itemsCount,
    setItemsPerPage,
    allItemsFetched,

    sort,
    setSort,
    setSorts,

    groupBy,
    setGroupBy,

    filters,
    setFilter,
    setFilters,

    removeListItem,
    addListItem,

    fetchNextItems,
  };
};

export default useList;
