// import AlertsContext from 'common/contexts/alerts';
import useList from "common/hooks/useList";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BellAlertIcon } from "@heroicons/react/24/outline";
import Header from "common/components/Header/Header";
import { User } from "types";

import FilteredList from "common/components/Table/FilteredList";
import useAuth from "common/hooks/useAuth";
import AlertsContext from "common/providers/alerts";
import NotificationCampaignItem from "./NotificationCampaignItem";

function NotificationCampaigns() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();
  const canCreateCampaigns = currentUser?.isAdmin;

  const {
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<User>("notifications-campaigns", {
    defaultSort: "createdAt:DESC",
    cachePrefix: "NotificationCampaigns",
    listenToEvents: ["notifications_campaigns_notifications_sent"],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const columns = useMemo(
    () => [
      {
        Header: t("forms.title"),
        filterName: "title$containsi",
        filterLabel: t("forms.title"),
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.notificationCampaigns")}
        subTitle={t("titleLegends.notificationCampaigns")}
        btnTitle={t("newItem.notificationCampaign")}
        btnSubtitle={t("newItem.notificationCampaignLegend")}
        Icon={BellAlertIcon}
        addLink="/notification-campaigns/add"
        addLinkDisabled={!canCreateCampaigns}
        isBeforeFilters
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={NotificationCampaignItem}
        onDelete={() => {}}
        onNavigate={() => {}}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default NotificationCampaigns;
